(function() {
    'use strict';

    angular.module('consulatApp').directive('confirm',ConfirmDirect);
    ConfirmDirect.$inject = ['ConfirmService'];

    function ConfirmDirect (ConfirmService) {
        return {
            restrict: 'A',
            scope: {
                eventHandler: '&ngClick'
            },
            link: function(scope, element, attrs){
                element.unbind("click");
                element.bind("click", function(e) {
                    ConfirmService.open(attrs.confirm, scope.eventHandler);
                });
            }
        }
        }
    })();

