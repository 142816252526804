(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembersCarteController', MembersCarteController);

        MembersCarteController.$inject = ['$scope','$http','$window','TableService','UserExtraConnected','CarteMultiParams','DateUtils'];

    function MembersCarteController($scope,$http,$window,TableService,UserExtraConnected,CarteMultiParams,DateUtils) {
        var vm = this;
        vm.init = init;
        vm.user = UserExtraConnected.query();
        vm.dateDebut = null;
        vm.dateFin = null;

        vm.itemSearch = {
            "dateDebut": null,
            "dateFin": null,
            "nom": null,
            "prenom": null,
            "numero": null,
            "metier": null,
            "statut":null,
            "sexe":null
        };

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;
        vm.filtrer=false;


        function openCalendar(date) {
            console.log("open");
            vm.datePickerOpenStatus[date] = true;
        }

        vm.dateFinEstAvantDateDebut = function () {
            var debut = new moment(vm.itemSearch.dateDebut);
            var fin = new moment(vm.itemSearch.dateFin);
            return fin.isBefore(debut);
        };


        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.itemSearch = {
                    "dateDebut": null,
                    "dateFin": null,
                    "nom": null,
                    "prenom": null,
                    "numero": null,
                    "metier": null,
                    "statut":null,
                    "sexe":null
                };
                init();
            }
        };

          init();
        function init() {
            if(vm.itemSearch.dateDebut!=null) vm.dateDebut = DateUtils.convertLocalDateToServer(vm.itemSearch.dateDebut);
            if(vm.itemSearch.dateFin!=null) vm.dateFin = DateUtils.convertLocalDateToServer(vm.itemSearch.dateFin);
            var addItemData = {};
            var editItemData = {};
            var detailItemData = {
                templateUrl: 'app/entities/carte/carte-dialog.html',
                controller: 'CarteDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    isViewing: function () {
                        return true;
                    },
                    entity: null //valeur déterminé à l'appel de la boite,
                }
            };            /**
             *Modification d'un élément
            */

            var deleteItemData = {};
            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function () {
                return {};
            };
            var entityParams = function () {
                return {
                    "dateDebut": vm.dateDebut,
                    "dateFin": vm.dateFin,
                    "nom": vm.itemSearch.nom,
                    "prenom": vm.itemSearch.prenom,
                    "numero": vm.itemSearch.numero,
                    "metier": vm.itemSearch.metier,
                    "statut":vm.itemSearch.statut,
                    "sexe":vm.itemSearch.sexe
                };
            };


            var datas = {
                scope: $scope,
                vm: vm,
                entity: CarteMultiParams,
                dialogData: dialogData,
                entitySearch: CarteMultiParams,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);
        }
    }

})();
