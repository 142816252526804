(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('MembreServiceTop10', MembreServiceTop10)
        .factory('MembreServiceTop10Even', MembreServiceTop10Even)
        .factory('MembreService', MembreService);

    MembreServiceTop10.$inject = ['$resource'];
    MembreServiceTop10Even.$inject = ['$resource'];
    MembreService.$inject = ['$resource'];

    function MembreService ($resource) {
        var resourceUrl =  'api/membre-services/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function MembreServiceTop10 ($resource) {
        var resourceUrl =  'api/membre-services/findTop10';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function MembreServiceTop10Even ($resource) {
        var resourceUrl =  'api/membre-services/findTop10Even';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }









})();
