(function() {
    'use strict';
angular.module('consulatApp').controller('ModalConfirmCtrl',ModalConfirmCtrl);

    ModalConfirmCtrl.$inject = ['$scope','$uibModalInstance','text'];

    function ModalConfirmCtrl($scope, $uibModalInstance, text) {

    $scope.text = text;

    $scope.ok = function () {
        $uibModalInstance.close(true);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
    }
})();
