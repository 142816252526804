(function() {
    "use strict";
    angular.module('consulatApp').directive('jSelect2Dialog', select);
    select.$inject = ['$uibModal', "$rootScope","PopupService"];

    function select($uibModal, $rootScope,PopupService) {
        var modalInstance;
        console.log("111111111111111111");
        return {
            restrict: 'AE',
            scope: {
                datas: '=jList',
                model: '=jModel',
                states:'=jStates',
                multiple: '=jMultiple',
                columns: '=jColumns',
                eventName: '@jEventName',
                onFilter: '&jOnSearch'
            },
            link: function(scope, iElement, iAttrs, iCtrl,$scope) {
                var d = {
                    templateUrl: 'app/components/select/select-dialog.html',
                    controller: function() {
                        console.log(scope);
                        var vm = this;

                        var ctrl = this;
                      //  ctrl.test = test;
                        ctrl.query = "";
                        ctrl.cancel2 = cancel2;
                        ctrl.confirm2 = confirm2;
                        ctrl.isChecked = isChecked;
                        ctrl.toggle = toggle;
                        ctrl.datas = scope.datas;
                        ctrl.columns = scope.columns;
                        ctrl.onFilter = scope.onFilter;
                        ctrl.check = check;
                        ctrl.uncheck = uncheck;
                        ctrl.multiple = scope.multiple;
                        ctrl.states = scope.states;
                        ctrl.toggleAll = toggleAll;
                        ctrl.getColumnTitle = getColumnTitle;
                        ctrl.getColumnName = getColumnName;

                        scope.$watch(angular.bind(scope, function() {
                            return scope.datas;
                        }), function(items) {
                            ctrl.datas = items;
                        });

//function test()
//{
    console.log("55555555555555");
                        var addItemData = {
                            templateUrl: 'app/entities/states/states-dialog.html',
                            controller: 'StatesDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                isViewing:function(){
                                    return false;
                                },
                                isHiding:function(){
                                    return true;
                                },
                                entity: function() {
                                    return {
                                        /*Initialisation des valeurs par défauts de l'entité à créer*/
                                        id: null,
                                        libelle: null,
                                        countryLibelle: null
                
                                    };
                                }
                            }
                        };


                        var addItemDataSecond = {
                            templateUrl: 'app/entities/city/city-dialog.html',
                            controller: 'CityDialogController',
                            service: 'City',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                isViewing:function(){
                                    return false;
                                },
                                isHiding:function(){
                                    return true;
                                },
                                entity: function() {
                                    return {
                                        /*Initialisation des valeurs par défauts de l'entité à créer*/
                                            id: null,
                                            libelle: null,
                                            countriesLibelle: null,
                                            statesLibelle: null
                    
                                    };
                                }
                            }
                        };
    
    
    
    
                        var dialogData = {
                            addData: addItemData,
                            addDataSecond:addItemDataSecond
                        };
                
                
                        var datasEmpty=[];
                
               
                        var statesWindow = {
                            vm: ctrl,
                            datas: datasEmpty,
                            dialogData: dialogData,
                            scope: $scope
                        };
                         
                        PopupService.open(statesWindow);
                
                        console.log("eeeeeeeeeeeeeeeee");
                        $rootScope.$on('PopupService:resultData', function(event, result) {
                            console.log(result.result);
                          
                            ctrl.datas.push(result.result);
                        

                         });





                        /**A SUIVRE : L'appel de la fonction ctrl.onFilter ne fonctionne pas ! Le
                         * newValue passé est toujours vide !
                         */
                        scope.$watch(angular.bind(ctrl, function() {
                            return ctrl.query;
                        }), function(newValue) {
                            scope.filterText = newValue;
                        });

                        ctrl.model = angular.copy(scope.model);
                        if (angular.isUndefined(ctrl.model)) {
                            if (ctrl.multiple) {
                                ctrl.model = [];
                            } else {
                                ctrl.model = '';
                            }
                        }

                        function getColumnTitle(column) {
                            if (angular.isDefined(column) && column !== null) {
                                if (column.indexOf(',') !== -1) {
                                    var items = column.split(',');
                                    if (items.length !== 2) {
                                        console.error("***Column " + column + " is incorrect");
                                    }
                                    return items[1].trim();
                                } else {
                                    return column.trim();
                                }
                            }
                            return "";
                        }

                        function getColumnName(column) {
                            if (angular.isDefined(column) && column !== null) {
                                if (column.indexOf(',') !== -1) {
                                    var items = column.split(',');
                                    if (items.length !== 2) {
                                        console.error("***Column " + column + " is incorrect");
                                    }
                                    return items[0];
                                } else {
                                    return column;
                                }
                            } else {
                                console.error("***Column name can't be null");
                                return "";
                            }
                        }
                        
                        function cancel2() {
                            modalInstance.dismiss('cancel');

                        }

                        function confirm2() {
                            modalInstance.close(ctrl.model);
                        }

                        function isChecked(item) {
                            if (ctrl.multiple) {
                                return _.includes(ctrl.model, item.id);
                            } else {
                                return ctrl.model === item.id;
                            }
                        }

                        function toggle(item) {
                            if (ctrl.isChecked(item)) {
                                ctrl.uncheck(item);
                            } else {
                                ctrl.check(item);
                            }
                        }

                        function toggleAll() {
                            if (ctrl.allSelected) {
                                angular.forEach(ctrl.datas, function(val, key) {
                                    ctrl.check(val);
                                });
                            } else {
                                angular.forEach(ctrl.datas, function(val, key) {
                                    ctrl.uncheck(val);
                                });
                            }
                        }

                        function check(item) {
                            if (ctrl.multiple) {
                                ctrl.model.push(item.id);
                                this.confirm2();
                            } else {
                                ctrl.model = item.id;
                                this.confirm2();
                            }
                        }

                        function uncheck(item) {
                            if (ctrl.multiple) {
                                var index=ctrl.model.indexOf(item.id);
                               ctrl.model.splice(index, 1);
                            } else {
                                ctrl.model = '';
                            }
                        }
                    },
                    controllerAs: 'ctrl',
                    size: 'md',
                    backdrop: 'static'


                };

                iElement.on("click", function(event) {
                    if (angular.isDefined(scope.eventName)) {
                        $rootScope.$broadcast(scope.eventName);
                    }
                    modalInstance = $uibModal.open(d);
                    modalInstance.result.then(
                        function(res) {
                            scope.model = res;
                        },
                        function() {

                        });
                });
            }
        };
    }
})();
