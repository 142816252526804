(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('DocumentLegalDetailController', DocumentLegalDetailController);

    DocumentLegalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DocumentLegal'];

    function DocumentLegalDetailController($scope, $rootScope, $stateParams, previousState, entity, DocumentLegal) {
        var vm = this;

        vm.documentLegal = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:documentLegalUpdate', function(event, result) {
            vm.documentLegal = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
