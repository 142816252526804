(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MetierDialogController', MetierDialogController);

    MetierDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Metier'];

    function MetierDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Metier) {
        var vm = this;

        vm.metier = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.metier.id !== null) {
                Metier.update(vm.metier, onSaveSuccess, onSaveError);
            } else {
                Metier.save(vm.metier, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:metierUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
