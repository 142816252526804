(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembreServiceDetailController', MembreServiceDetailController);

    MembreServiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MembreService', 'InfoService'];

    function MembreServiceDetailController($scope, $rootScope, $stateParams, previousState, entity, MembreService, InfoService) {
        var vm = this;

        vm.membreService = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:membreServiceUpdate', function(event, result) {
            vm.membreService = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
