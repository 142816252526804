(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('CarteController', CarteController);

        CarteController.$inject = ['$scope','$http','$window','DateUtils','TableService','UserExtraConnected',
            'CarteMultiParams'];

    function CarteController($scope,$http,$window,DateUtils,TableService,UserExtraConnected,CarteMultiParams
                                    ) {
        var vm = this;

        vm.init = init;

        var fileURLPDF;
        vm.etatCarte = etatCarte;

        vm.user = UserExtraConnected.query();

        function etatCarte(id) {
            $http.get('/api/cartes/cartePDF', {
                    responseType: 'arraybuffer',
                    params: {
                        idCarte:id
                    }

                })
                .success(function(response) {
                    var file = new Blob([(response)], {
                        type: 'application/pdf'
                    });
                    fileURLPDF = URL.createObjectURL(file);
                    $window.open(fileURLPDF, '_blank');
                });
           }

        vm.itemSearch = {
            "nom": null,
            "prenom": null,
            "numero": null,
            "metier": null
        };
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;
        vm.filtrer=false;



        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.itemSearch = {
                    "nom": null,
                    "prenom": null,
                    "numero": null,
                    "metier": null
                };
                init();
            }
        }

          init();
        function init() {
          /*   if(vm.itemSearch.dateArrivee!=null)
            vm.date = null;
            vm.date = DateUtils.convertLocalDateToServer(vm.itemSearch.dateArrivee); */
            var addItemData = {};
            var editItemData = {};
            /**
             *Modification d'un élément
            */
       var editItemData = {
        templateUrl: 'app/entities/carte/carte-detail.html',
        controller: 'CarteDetailController',
        controllerAs: 'vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
            entity: null
        }
    };

            var detailItemData = {
                templateUrl: 'app/entities/carte/carte-dialog.html',
                controller: 'CarteDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    isViewing: function () {
                        return true;
                    },
                    entity: null //valeur déterminé à l'appel de la boite,
                }
            };

            var deleteItemData = {};
            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function () {
                return {};
            };
            var entityParams = function () {
                return {
                    "nom": vm.itemSearch.nom,
                    "prenom": vm.itemSearch.prenom,
                    "numero": vm.itemSearch.numero,
                    "metier": vm.itemSearch.metier
                };
            };


            var datas = {
                scope: $scope,
                vm: vm,
                entity: CarteMultiParams,
                dialogData: dialogData,
                entitySearch: CarteMultiParams,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);
        }
    }

})();
