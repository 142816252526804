(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('CarteDeleteController',CarteDeleteController);

    CarteDeleteController.$inject = ['$uibModalInstance', 'entity', 'Carte'];

    function CarteDeleteController($uibModalInstance, entity, Carte) {
        var vm = this;

        vm.carte = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Carte.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
