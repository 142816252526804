(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('AdressesByMember', AdressesByMember)
        .factory('AdressesParams', AdressesParams)
        .factory('Adresses', Adresses);

    AdressesByMember.$inject = ['$resource'];
    AdressesParams.$inject = ['$resource'];
    Adresses.$inject = ['$resource'];


    function AdressesParams ($resource) {
        var resourceUrl =  'api/adresses/findByParams';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }




    function AdressesByMember ($resource) {
        var resourceUrl =  'api/adresses/member/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }


    function Adresses ($resource) {
        var resourceUrl =  'api/adresses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
