(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal','DateUtils', 'LoginService', '$state','MembreServiceTop10','MembreServiceTop10Even','$rootScope','Auth','$http','ConsulatTop1'];

    function HomeController ($scope, Principal,DateUtils, LoginService, $state,MembreServiceTop10,MembreServiceTop10Even,$rootScope,Auth,$http,ConsulatTop1) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.manageCharts = manageCharts;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.periodeDebut = null;
        vm.periodeFin = null;




        vm.authenticationError = false;
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        vm.dateFinEstAvantDateDebut = function () {
            var debut = new moment(vm.periodeDebut);
            var fin = new moment(vm.periodeFin);
            return fin.isBefore(debut);
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }






        vm.list=MembreServiceTop10.query();

        vm.listEven=MembreServiceTop10Even.query();
        console.log(vm.list);
        console.log(vm.listEven);


        $scope.$watch("vm.periodeFin", function(fin) {
            if(fin!=null){
                vm.manageCharts();
        }
        });






        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {

            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if(account!=null){
                    vm.consulat = ConsulatTop1.get();
                    console.log(vm.account);
                var result = vm.account.authorities.find(function(n){
                    return n==='ROLE_MEMBRE';
                });

                if(result=="ROLE_MEMBRE"){
                    vm.manageCharts();

                }
                }


            });
        }
        function register () {
            $state.go('register');
        }




        function manageCharts() {
            /*Gestion des graphes de statistiques*/

            vm.membreSexe = {};
            vm.membreAssoc = {};
            vm.etatMembre = {};
            vm.membreArrivee = {};
            vm.trancheAge = {};


            vm.trancheAge.labels = [];
            vm.trancheAge.data = [];
            vm.trancheAge.options = {
                legend: {
                    display: false,
                    position: 'up'
                }
            };

            vm.membreSexe.labels = [];
            vm.membreSexe.data = [];
            vm.membreSexe.options = {
                legend: {
                    display: true,
                    position: 'left'
                }
            };

            vm.membreAssoc.labels = [];
            vm.membreAssoc.data = [];
            vm.membreAssoc.options = {
                legend: {
                    display: false,
                    position: 'left'
                }
            };

            vm.etatMembre.labels = [];
            vm.etatMembre.data = [];
            vm.etatMembre.options = {
                legend: {
                    display: false,
                    position: 'left'
                }
            };

            vm.membreArrivee.labels = [];
            vm.membreArrivee.data = [];
            vm.membreArrivee.options = {
                legend: {
                    display: true,
                    position: 'left'
                }
            };


         /*   vm.poseEffectue.labels = [];
            vm.poseEffectue.data = [];
            vm.poseEffectue.options = {
                legend: {
                    display: false,
                    position: 'top'
                }
            };*/

            $http.get("api/statistiques/dateNaissanceMembers", {params: {}}).then(
                function(response) {
                    console.log(response.data);
                    vm.trancheAge.labels = response.data.age;
                    vm.trancheAge.data = response.data.data;
                }
            );

            $http.get("api/statistiques/dateArriveeMembers", {params: {debut: vm.periodeDebut,fin:vm.periodeFin}}).then(
                function(response) {
                    console.log(response.data);
                    vm.membreArrivee.labels = response.data.total;
                    vm.membreArrivee.data = response.data.data;
                }
            );

            $http.get("api/statistiques/membreSexe", {params: {}}).then(
                function(response) {
                    console.log(response.data);
                    vm.membreSexe.labels = response.data.etats;
                    vm.membreSexe.data = response.data.data;
                }
            );

            $http.get("api/statistiques/assocMembers", {params: {}}).then(
                function(response) {
                    console.log(response.data);
                    vm.membreAssoc.labels = response.data.types;
                    vm.membreAssoc.data = response.data.data;
                }
            );

            $http.get("api/statistiques/etatMembers", {params: {}}).then(
                function(response) {
                    console.log(response.data);
                    vm.etatMembre.labels = response.data.etats;
                    vm.etatMembre.data = response.data.data;
                }
            );


        }









        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                /*
                $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }*/

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }



        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
















    }
})();
