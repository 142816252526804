(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('AdressesController', AdressesController);

    AdressesController.$inject = ['$scope', 'AdressesParams', 'TableService'];

    function AdressesController($scope, AdressesParams, TableService) {

        var vm = this;
        vm.init = init;
      
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.itemSearch = {
            "rue": null,
            "telephone": null,
            "zipCode": null,
            "membreNom": null
        };


        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.itemSearch = {
                    "rue": null,
                    "telephone": null,
                    "zipCode": null,
                    "membreNom": null
                     
                };
                init();
            }
        }



          init();
        function init() {

            var addItemData = {};

            var editItemData = {};

            var addItemData ={
                templateUrl: 'app/entities/adresses/adresses-dialog.html',
                controller: 'AdresseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                          
                 rue:null,
                 noPorte:null,
                 telephone:null,
                 mail:null,
                 zipCode:null,
                 autre:null,
                 cityId:null,
                 etatId:null
             
                            
                        };
                    }
                }
            };
         
            /**
             *Modification d'un élément
             */
            var editItemData = {
                templateUrl: 'app/entities/adresses/adresses-dialog.html',
                controller: 'AdresseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: null
                }
            };
     
            /**
             * Suppresion d'un élément
             */
            var deleteItemData ={
                templateUrl: 'app/entities/adresses/adresses-delete-dialog.html',
                controller: 'AdresseDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: null
                }
            };
     
              var detailItemData = {
                templateUrl: 'app/entities/adresses/adresses-detail.html',
                controller: 'AdresseDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null, //valeur déterminé à l'appel de la boite
                     isViewing: true
                }
            };

            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function () {
                return {};
            };
            var entityParams = function () {
                return {
                    "nom": vm.itemSearch.nom,
                    "prenom": vm.itemSearch.prenom
                 
                    
                };
            };


            var datas = {
                scope: $scope,
                vm: vm,
                entity: AdressesParams,
                dialogData: dialogData,
                entitySearch: AdressesParams,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            console.log(datas);
            TableService.init(datas);
        }



     
   }
})();
