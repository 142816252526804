(function() {
    'use strict';

    angular
        .module('consulatApp', [
            'ngStorage',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'angularMoment',
            'ui-notification',
            'chart.js',
            'signature',
            'tmh.dynamicLocale',
            'ngImgCrop'
            // App

   // 'app.dashboard'

        ])
        .constant('_', window._) // Ajout de la librairie lodash
        .run(run);

        run.$inject = ['stateHandler', 'translationHandler','Notification','$rootScope'];

        function run(stateHandler, translationHandler,Notification,$rootScope) {
            stateHandler.initialize();
            translationHandler.initialize();
            $rootScope.$on('consulatApp.httpError', function(event, err) {
                console.log(err);
                if (err && err.data && err.status !== 500) {
                    if(err.data.message!="Not Found")
                        Notification.error(err.data.message);
                } else if (err.status === 500) {
                    Notification.error("Nous avons rencontré une erreur.");
                }
            });
        }
        angular.module('consulatApp').config(appConfig);

        appConfig.$inject = ['ChartJsProvider'];

        function appConfig(ChartJsProvider) {
            // Configure all charts
            ChartJsProvider.setOptions({
                colors: ['#34A853', '#DCDCDC', '#FBBC05', '#EA4235', '#FDB45C', '#4284F4', '#4D5360']
            });
            Chart.defaults.global.responsive = true;

            Chart.defaults.global.scaleFontFamily = $.urbanApp.font;

            Chart.defaults.global.scaleFontSize = 10;

            Chart.defaults.global.tooltipFillColor = $.urbanApp.primary;

            Chart.defaults.global.tooltipFontFamily = $.urbanApp.font;

            Chart.defaults.global.tooltipFontSize = 12;

            Chart.defaults.global.tooltipTitleFontFamily = $.urbanApp.font;

            Chart.defaults.global.tooltipTitleFontSize = 13;

            Chart.defaults.global.tooltipTitleFontStyle = '700';

            Chart.defaults.global.tooltipCornerRadius = 2;
        }
})();
