(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('AssociationDialogController', AssociationDialogController);

    AssociationDialogController.$inject = ['City', '$scope', 'DataUtils', '$uibModalInstance', 'entity', 'Association', 'States','CityByStateId','Adresses','TypeAssociation'];

    function AssociationDialogController (City, $scope,DataUtils, $uibModalInstance, entity, Association, States,CityByStateId,Adresses,TypeAssociation) {
        var vm = this;
   
        vm.association = entity;
        console.log(vm.association);
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.datePickerOpenStatus = {};
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.types = TypeAssociation.query();
        vm.association.dateCreation = new Date(vm.association.dateCreation);

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        
if(vm.association.adressesId!=null)
{
    console.log("*******************************************"+vm.association);
        Adresses.get({id:vm.association.adressesId},
            function (data) {
               vm.association.adresseDTO=data;
               

               City.get({id:data.cityId},
                function (datas) {
                   vm.pays=datas;
                   vm.association.adresseDTO.etatId=datas.statesId;
                   console.log(vm.pays);
                    }
                )
                }
            )
        }
      

        vm.clear = clear;
             /*****************************Gestionnaire des onglets**************************************/
             vm.steps = [
                'IDENTIFICATION',
                'ADRESSE'
            ];
    
    
            vm.selection = vm.steps[0];
            vm.error = false;
            vm.showOnglet = showOnglet;
            vm.getCurrentStepIndex = function() {
                return vm.steps.indexOf(vm.selection);
            };
        
                 function showOnglet(onglet) {
                var stepIndex = vm.getCurrentStepIndex();
                return (vm.selection.toUpperCase() === onglet.toUpperCase());
            }
    
            // Go to a defined step index
            vm.goToStep = function(index, editForm) {
                if (!editForm.$invalid || (vm.getCurrentStepIndex() > index) ) {
                    if (!angular.isUndefined(vm.steps[index])) {
                        console.log("change step");
                        vm.selection = vm.steps[index];
                    }
                }
            };
    
            vm.hasNextStep = function() {
                var stepIndex = vm.getCurrentStepIndex();
                var nextStep = stepIndex + 1;
                // Return true if there is a next step, false if not
                return !angular.isUndefined(vm.steps[nextStep]);
            };
    
            vm.hasPreviousStep = function() {
                var stepIndex = vm.getCurrentStepIndex();
                var previousStep = stepIndex - 1;
                // Return true if there is a next step, false if not
                return !angular.isUndefined(vm.steps[previousStep]);
            };
    
            vm.incrementStep = function() {
                if (vm.hasNextStep()) {
                    //Vérification des champs du step précedent
                    var stepIndex = vm.getCurrentStepIndex();
                    var nextStep = stepIndex + 1;
                    vm.selection = vm.steps[nextStep];
                }
            };
    
    
            vm.decrementStep = function() {
                if (vm.hasPreviousStep()) {
                    var stepIndex = vm.getCurrentStepIndex();
                    var previousStep = stepIndex - 1;
                    vm.selection = vm.steps[previousStep];
                }
            };
       

            vm.etatColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadEtats", function () {
    
            });
    loadEtats();
            function loadEtats() {
                States.query({
                    size: 10000
                }, function (data) {
                    console.log("l'oganisation");
                    if (data != null) {
                        console.log(data);
                        vm.etats = [];
                        vm.etats = data;
                    }
                });
    
                
               // vm.members.lieuNaissance = test.libele;
            }
    
    
            vm.statesVilleColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadStatesVille", function () {
    
            });


            $scope.$watch("vm.association.adresseDTO.etatId", function () {
                console.log("Dans le watch");
                loadEtats();
        if(vm.association.adresseDTO.etatId!=null )
        {
                CityByStateId.query ({
                        id: vm.association.adresseDTO.etatId
                    }, function (data) {
                        vm.villeAdresse = data;
                    })
                }
                
            });

            $scope.$watch("vm.association.adresseDTO.cityId", function () {
                console.log("Dans le watch");
        if(vm.association.adresseDTO.etatId!=null )
        {
                CityByStateId.query ({
                        id: vm.association.adresseDTO.etatId
                    }, function (data) {
                        vm.villeAdresse = data;
                    })
                }
                
            });




        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.association.id !== null) {
                Association.update(vm.association, onSaveSuccess, onSaveError);
            } else {
                Association.save(vm.association, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('associationApp:associationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
