(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('InfoAssociationByMember', InfoAssociationByMember)
        .factory('InfoAssociation', InfoAssociation);

    InfoAssociationByMember.$inject = ['$resource', 'DateUtils'];
    InfoAssociation.$inject = ['$resource', 'DateUtils'];


    function InfoAssociationByMember ($resource, DateUtils) {
        var resourceUrl =  'api/info-associations/association/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateEntree = DateUtils.convertLocalDateFromServer(data.dateEntree);
                        data.dateSortie = DateUtils.convertLocalDateFromServer(data.dateSortie);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateEntree = DateUtils.convertLocalDateToServer(copy.dateEntree);
                    copy.dateSortie = DateUtils.convertLocalDateToServer(copy.dateSortie);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateEntree = DateUtils.convertLocalDateToServer(copy.dateEntree);
                    copy.dateSortie = DateUtils.convertLocalDateToServer(copy.dateSortie);
                    return angular.toJson(copy);
                }
            }
        });
    }







    function InfoAssociation ($resource, DateUtils) {
        var resourceUrl =  'api/info-associations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateEntree = DateUtils.convertLocalDateFromServer(data.dateEntree);
                        data.dateSortie = DateUtils.convertLocalDateFromServer(data.dateSortie);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateEntree = DateUtils.convertLocalDateToServer(copy.dateEntree);
                    copy.dateSortie = DateUtils.convertLocalDateToServer(copy.dateSortie);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateEntree = DateUtils.convertLocalDateToServer(copy.dateEntree);
                    copy.dateSortie = DateUtils.convertLocalDateToServer(copy.dateSortie);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
