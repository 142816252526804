(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('InfoParentsDetailController', InfoParentsDetailController);

    InfoParentsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InfoParents'];

    function InfoParentsDetailController($scope, $rootScope, $stateParams, previousState, entity, InfoParents) {
        var vm = this;

        vm.infoParents = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:infoParentsUpdate', function(event, result) {
            vm.infoParents = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
