(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('DocumentLegalDialogController', DocumentLegalDialogController);

    DocumentLegalDialogController.$inject = ['$timeout', '$scope', 'Country', '$uibModalInstance', 'entity', 'DocumentLegal'];

    function DocumentLegalDialogController ($timeout, $scope, Country, $uibModalInstance, entity, DocumentLegal) {
        var vm = this;

        vm.documentLegal = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.countryColumns = [{
            name: 'libele',
            title: 'Libelle'
        }];
        $scope.$on("onLoadCountries", function () {

        });
        loadCountries();

        function loadCountries() {
            Country.query({
                size: 10000
            }, function (data) {
                console.log("les pays");
                if (data != null) {
                    console.log(data);
                    vm.countries = [];
                    vm.countries = data;
                }
            });


           // vm.members.lieuNaissance = test.libele;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.documentLegal.id !== null) {
                DocumentLegal.update(vm.documentLegal, onSaveSuccess, onSaveError);
            } else {
                DocumentLegal.save(vm.documentLegal, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:documentLegalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateExpr = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
