(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('TypeAssociationDialogController', TypeAssociationDialogController);

    TypeAssociationDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'TypeAssociation'];

    function TypeAssociationDialogController ($timeout, $scope, $uibModalInstance, entity, TypeAssociation) {
        var vm = this;
        vm.typeAssociation = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.typeAssociation.id !== null) {
                TypeAssociation.update(vm.typeAssociation, onSaveSuccess, onSaveError);
            } else {
                TypeAssociation.save(vm.typeAssociation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:typeAssociationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
