(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('ProfilDialogController', ProfilDialogController);

    ProfilDialogController.$inject = ['Principal', '$scope', '$uibModalInstance', 'entity', 'Profil'];

    function ProfilDialogController (Principal, $scope, $uibModalInstance, entity, Profil) {
        var vm = this;
        vm.profil = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isAdminG = false;
        vm.authorities = [];
        vm.uos = [];
        console.log("Les authorities"+vm.profil.authorities);

        vm.hasRole = function (role) {
            return vm.authorities_user.indexOf(role) != -1
        }
        vm.profil_authorities = null;
        if (vm.profil.id != null) {
            vm.profil_authorities = vm.profil.authorities;
        }
        /*
           Si l'utilisateur connecte n'est pas un l'admin generale on recupere les roles de son profil
         */
        Principal.identity().then(function (account) {
            //Le compte de l'utilisateur connecte
            vm.currentAccount = account;
            console.log(vm.currentAccount);
            vm.authorities_user = vm.currentAccount.authorities;
            if (vm.hasRole("ROLE_ADMIN")) {
                console.log("admin general")
                vm.isAdminG = true;

                vm.authorities = vm.authorities_user;
                var i = 0;
                vm.auth = [];
                angular.forEach(vm.authorities, function (value, index) {
                    if(value!="ROLE_ADMIN")
                    {
                    var v = {};
                    v.name = value;
                    i++;
                    v.id = i;
                    vm.auth.push(v);
                    }
                });
                majUserRoles();


            } else {
                console.log("utilisateur simple");
                /*
                    On recupere l'organisation de l'utilisateur connecte et les organisations fils de celui
                    s'il est pere
                 */
                vm.authorities = vm.authorities_user;
                var i = 0;
                vm.auth = [];
                angular.forEach(vm.authorities, function (value, index) {
                    var v = {};
                    v.name = value;
                    i++;
                    v.id = i;
                    vm.auth.push(v);
                });
                majUserRoles();
            }
        });

        function majUserRoles() {

            if (angular.isDefined(vm.profil) && vm.profil != null) {
                if (vm.profil.id != null) {
                    vm.authIds = [];
                    angular.forEach(vm.profil.authorities, function (value, index) {
                        var auth = _.find(vm.auth, { 'name': value });
                        if(angular.isDefined(auth)) {
                            vm.authIds.push(auth.id);
                        }
                    });
                }
            }

        }

        /*
        Si l'utilisateur connecte est l'admin generale on recupere les roles
        de l'organisation selectionne
       */
      /*  $scope.$watch("vm.profil.organisationId", function (organisationId) {
            if(angular.isDefined(organisationId)){
                vm.authorities=[];
                RequestUtils.GetRolesOfTypeUniteOrganisation.query({id:organisationId}, function (datas) {
                    console.log("liste des roles");
                    if (datas != null) {
                        var i = 0;
                        vm.auth = [];
                        angular.forEach(datas, function (value, index) {
                            i++;
                            value.id = i;
                            vm.auth.push(value);
                            vm.authorities.push(value.name);
                        });
                    }
                    majUserRoles();
                });
            }
        });*/


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        /*
            Comparer deux liste
        */
        function isSame(arrayOne, arrayTwo) {
            var a = arrayOne,
                b = arrayTwo;

            if (arrayOne.length <= arrayTwo.length) {
                a = arrayTwo;
                b = arrayOne;
            }
            return _.isEmpty(_.difference(a.sort(), b.sort()));
        }


        /*
           Enregistrement ou mise à jour
        */
        function save() {
            vm.isSaving = true;
            console.log("enregistrement/mise à jour");
            if (vm.profil.id != null) {
                console.log(isSame(vm.profil.authorities, vm.profil_authorities));
                vm.profil.profilsChange = !isSame(vm.profil.authorities, vm.profil_authorities);
                vm.isSaving = false;
            }
            if (vm.profil.id !== null) {
                Profil.update(vm.profil, onSaveSuccess, onSaveError);
            } else {
                Profil.save(vm.profil, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('imanetApp:profilUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.roleColums = [{
            name: 'name',
            title: 'Role'
        }];

        $scope.$on("onLoadRoles", function () {

        });

        $scope.$watch("vm.authIds",function (ids) {
            if(angular.isDefined(ids)){
                vm.profil.authorities = [];
                angular.forEach(ids,function (value,index) {
                    var auth = _.find(vm.auth,{'id':value});
                    vm.profil.authorities.push(auth.name);
                });
            }
        });

    }
})();
