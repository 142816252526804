(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembreServiceController', MembreServiceController);

    MembreServiceController.$inject = ['$scope', 'MembreService', 'TableService','DataUtils','UserExtraConnected'];

    function MembreServiceController($scope, MembreService, TableService,DataUtils,UserExtraConnected) {

        var vm = this;
        vm.init = init;
        vm.datePickerOpenStatus = {};
        vm.openFile = DataUtils.openFile;
        vm.openCalendar = openCalendar;
        vm.user = UserExtraConnected.query();



        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.itemSearch = {
            "dateDebut": null,
            "dateFin": null
        };


        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.itemSearch = {
                    "dateDebut": null,
                    "dateFin": null   
                };
                init();
            }
        }



          init();
        function init() {
          /*  if(vm.itemSearch.dateDebut!=null && vm.itemSearch.dateFin!=null )
            {
            vm.itemSearch.dateDebut = DateUtils.convertLocalDateToServer(vm.itemSearch.dateDebut);
            vm.itemSearch.dateFin = DateUtils.convertLocalDateToServer(vm.itemSearch.dateFin);

            }*/



            var addItemData ={
                templateUrl: 'app/entities/membre-service/membre-service-dialog.html',
                controller: 'MembreServiceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'mg',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            numero:null,
                            libelle: null
                        };
                    }
                }
            };
    
            /**
             *Modification d'un élément
             */
            var editItemData = {
                templateUrl: 'app/entities/membre-service/membre-service-dialog.html',
                controller: 'MembreServiceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null
                }
            };
    
            /**
             * Suppresion d'un élément
             */
            var deleteItemData ={
                templateUrl: 'app/entities/membre-service/membre-service-delete-dialog.html',
                controller: 'MembreServiceDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: null
                }
            };
    
              var detailItemData = {
                templateUrl: 'app/entities/membre-service/membre-service-detail.html',
                controller: 'CountryDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null, //valeur déterminé à l'appel de la boite
                     isViewing: true
                }
            };
    
            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                deleteData: deleteItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function() {
                return {
    
                };
            };
    
            var entityParams = function() {
                return {
                    "debut": vm.itemSearch.dateDebut,
                    "fin": vm.itemSearch.dateFin
                };
            };
    
            var datas = {
                scope: $scope,
                vm: vm,
                entity: MembreService,
                entitySearch: MembreService,
                dialogData: dialogData,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);

        }

        var addItemData ={
            templateUrl: 'app/entities/membre-service/membre-service-dialog.html',
            controller: 'MembreServiceDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'mg',
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        numero:null,
                        libelle: null
                    };
                }
            }
        };

        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/membre-service/membre-service-dialog.html',
            controller: 'MembreServiceDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData ={
            templateUrl: 'app/entities/membre-service/membre-service-delete-dialog.html',
            controller: 'MembreServiceDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

          var detailItemData = {
            templateUrl: 'app/entities/membre-service/membre-service-detail.html',
            controller: 'CountryDetailController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null, //valeur déterminé à l'appel de la boite
                 isViewing: true
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData,
            detailData: detailItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {

            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: MembreService,
            entitySearch: MembreService,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();