(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('TypeAssociationController', TypeAssociationController);

        TypeAssociationController.$inject = ['$scope', 'TypeAssociation', 'TableService'];

    function TypeAssociationController($scope, TypeAssociation, TableService) {

        var vm = this;

        var addItemData ={
            templateUrl: 'app/entities/association/type-associations-dialog.html',
            controller: 'TypeAssociationDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'mg',
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        libelle: null
                    };
                }
            }
        };

        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/association/type-associations-dialog.html',
            controller: 'TypeAssociationDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData ={
            templateUrl: 'app/entities/association/type-associations-delete-dialog.html',
            controller: 'TypeAssociationDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {

            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: TypeAssociation,
            entitySearch: TypeAssociation,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();