(function () {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembersController', MembersController);

    MembersController.$inject = ['$scope', '$filter', 'Poste', 'UserExtraConnected', '$rootScope', 'PopupService', '$http', '$window', 'Notification', '$state', 'DataUtils', 'Members', 'Association', 'Country', 'City', 'States', 'CityByStateId', 'CityByCountryId', 'DocumentLegal', 'Metier','MemberExisting','DateUtils'];

    function MembersController($scope, $filter, Poste, UserExtraConnected, $rootScope, PopupService, $http, $window, Notification, $state, DataUtils, Members, Association, Country, City, States, CityByStateId, CityByCountryId, DocumentLegal, Metier,MemberExisting,DateUtils) {

        var vm = this;
        vm.saveMembre = saveMembre;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.user = UserExtraConnected.query();
        vm.documents = DocumentLegal.query();
        var asArray = [];
        vm.essai = essai;

        vm.file = null;
        vm.croped = null;


        function essai() {
            // vm.setPhoto(vm.croped,vm.members);
            // vm.members.photo = vm.croped;
            // vm.members.photoContentType = vm.file.type;
            // console.log(vm.file);
            // console.log(vm.croped);
            // vm.setPhoto(vm.file,vm.members);
            // console.log("------------------------------------------------------------------------------------------------------------");
            // console.log(vm.members);


        }


        $scope.upload = function (dataUrl) {
            Upload.upload({
                //    url: 'https://angular-file-upload-cors-srv.appspot.com/upload',
                data: {
                    file: Upload.dataUrltoBlob(dataUrl)
                },
            }).then(function (response) {
                $timeout(function () {
                    $scope.result = response.data;
                });
            }, function (response) {
                if (response.status > 0) $scope.errorMsg = response.status
                    + ': ' + response.data;
            }, function (evt) {
                $scope.progress = parseInt(100.0 * evt.loaded / evt.total);
            });
        }


        var fileURLPDF;

        vm.etatCarte = etatCarte;

        vm.members = {
            id: null,
            numero: null,
            nom: null,
            prenom: null,
            nomConjoint: null,
            prenomConjoint: null,
            dateNaissance: null,
            lieuNaissance: null,
            sexe: null,
            mariage: null,
            signatureScan: null,
            dateArrivee: null,
            villeArrivee: null,
            etatArrivee: null,
            metier: null,
            occupation: null,
            memePaysConjoint: null,
            parentsUsa: null,
            mumInUsa: null,
            noConjoint: null,
            taille: null,
            country: {
                cityId: null,
                statesId: null,
                numero: null,
                autorite: null,
                dateDelivr: null,
                dateExpr: null
            },
            bfIdDoc: {
                type: null,
                numero: null,
                docId: null,
                cityId: null,
                dateDelivr: null,
                dateExpr: null,
            },
            infoParents: {
                nomPere: null,
                prenomPere: null,
                nomMere: null,
                prenomMere: null,
                noPere: null,
                noMere: null

            },
            adresseDTO: {
                rue: null,
                noPorte: null,
                telephone: null,
                mail: null,
                zipCode: null,
                autre: null,
                cityId: null,
                city: null,
                etatId: null,
                etat: null
            },
            associations: []
        };

        /*  if(entity.id!=null)
         {
         vm.members = entity;
         } */
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.mariage = mariage;
        vm.ville = ville;
        vm.etat = etat;
        vm.pereUsa = pereUsa;
        vm.mereUsa = mereUsa;
        vm.association = association;
        vm.villes = [];

        function saveMembre() {

            if (vm.croped != null) {
                vm.members.photo = vm.croped;
                vm.members.photoContentType = vm.file.type;
            }

            if (!vm.members.scanSignature) {
                if (vm.members.signature != null) {
                    var canvas = document.createElement("canvas");
                    var dataURL = canvas.toDataURL("image/png");
                    vm.members.signature = vm.members.signature.substring("data:image/png;base64,".length);
                    vm.members.photo = vm.members.photo.substring("data:image/png;base64,".length);

                } else {
                    var canvas = document.createElement("canvas");
                    var dataURL = canvas.toDataURL("image/png");
                    vm.members.photo = vm.members.photo.substring("data:image/png;base64,".length);
                }
            } else {
                var canvas = document.createElement("canvas");
                var dataURL = canvas.toDataURL("image/png");
                vm.members.photo = vm.members.photo.substring("data:image/png;base64,".length);

            }
            vm.isSaving = true;
            if (vm.members.id !== null) {
                Members.update(vm.members, onSaveSuccess, onSaveError);
            } else {
                Members.save(vm.members, onSaveSuccess, onSaveError);
            }
        }

        function etatCarte(resultat) {
            $http.get('/api/members/cartePDF', {
                responseType: 'arraybuffer',
                params: {
                    idMembre: resultat.id,
                    changement: false
                }

            })
                .then(function (response) {
                    var file = new Blob([(response)], {
                        type: 'application/pdf'
                    });
                    fileURLPDF = URL.createObjectURL(file);
                    $window.open(fileURLPDF, '_blank');
                },function (err) {
                    console.log(err);
                });
        }

        function onSaveSuccess(resultat) {
            Notification.success("Le membre a été enregistré avec succès!");
            $state.reload();
            vm.isSaving = false;
            // vm.etatCarte(resultat);
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function mariage() {
            /* if(vm.members.mariage==false || vm.members.mariage==null)
             {
             vm.members.mariage = false;
             console.log("falsssss");

             }
             else
             {
                 vm.members.mariage = true;
                 console.log("trueeeee");

             }*/
        }

        function ville(bol) {
            //  vm.members.memePaysConjoint = bol;

        }

        function etat(bol) {
            //  vm.members.etatBol = bol;

        }

        function pereUsa(bol) {
            // vm.members.parentsUsa = bol;

        }

        function mereUsa(bol) {
            //    vm.members.mumInUsa = bol;

        }

        function association(bol) {
            vm.members.association = bol;
        }


        function openCalendar(date) {
            vm.datePickerOpenStatus.date;


            vm.datePickerOpenStatus[date] = true;
        }


        /*****************************Gestionnaire des onglets**************************************/
        vm.steps = [
            'IDENTIFICATION',
            'CONTACTS AND ARRIVALS',
            'IDENTIFICATION DOC',
            'ASSOCIATION'
        ];


        vm.selection = vm.steps[0];
        vm.error = false;
        vm.showOnglet = showOnglet;
        vm.getCurrentStepIndex = function () {
            return vm.steps.indexOf(vm.selection);
        };

        function showOnglet(onglet) {
            var stepIndex = vm.getCurrentStepIndex();
            return (vm.selection.toUpperCase() === onglet.toUpperCase());
        }

        // Go to a defined step index
        vm.goToStep = function (index, editForm) {
            console.log("goToStep---------------------------------");

            if (vm.user.id != null) {
                if (!editForm.$invalid || (vm.getCurrentStepIndex() > index)) {
                    if (!angular.isUndefined(vm.steps[index])) {
                        vm.selection = vm.steps[index];
                    }
                }
            } else {
                Notification.error("L'administrateur principal ne peut enregistrer un membre'!")

            }
        };

        vm.hasNextStep = function () {
            var stepIndex = vm.getCurrentStepIndex();
            var nextStep = stepIndex + 1;
            // Return true if there is a next step, false if not
            console.log("fhasNextStep---------------------------------");
            return !angular.isUndefined(vm.steps[nextStep]);
        };

        vm.hasPreviousStep = function () {
            var stepIndex = vm.getCurrentStepIndex();
            var previousStep = stepIndex - 1;
            // Return true if there is a next step, false if not
            return !angular.isUndefined(vm.steps[previousStep]);
        };

        vm.incrementStep = function () {
            console.log(vm.getCurrentStepIndex()+"incrementStep---------------------------------");
            //vm.members.signature = null;

           // vm.members.dateNaissance = new Date(vm.members.dateNaissance );
            if(vm.getCurrentStepIndex()==0)
            {

           var url ='api/members/test';
           var copy = angular.copy(vm.members);
                    copy.dateNaissance = DateUtils.convertLocalDateToServer(copy.dateNaissance);
                    copy.dateArrivee = DateUtils.convertLocalDateToServer(copy.dateArrivee);
                    copy.signature = null;

            $http.post(url, copy)
            .then(
             function(response){
            //success callback
             if (vm.user.id != null) {
            if (vm.hasNextStep()) {
                //Vérification des champs du step précedent
                var stepIndex = vm.getCurrentStepIndex();
                var nextStep = stepIndex + 1;
                vm.selection = vm.steps[nextStep];
            }
        } else {
            Notification.error("L'administrateur principal ne peut enregistrer un membre'!")

        }
                 },
            function(response){
            //failure callback
               });

            }
            else
            {
                if (vm.hasNextStep()) {
                    //Vérification des champs du step précedent
                    var stepIndex = vm.getCurrentStepIndex();
                    var nextStep = stepIndex + 1;
                    vm.selection = vm.steps[nextStep];
                }

            }


        };


        vm.decrementStep = function () {
            if (vm.hasPreviousStep()) {
                var stepIndex = vm.getCurrentStepIndex();
                var previousStep = stepIndex - 1;
                vm.selection = vm.steps[previousStep];
            }
        };


        vm.paysColumns = [{
            name: 'libele',
            title: 'Libelle'
        }];
        $scope.$on("onLoadPays", function () {

        });
        loadPays();

        function loadPays() {
            Country.query({
                size: 10000
            }, function (data) {
                if (data != null) {
                    vm.pays = [];
                    vm.pays = data;
                }
            });
        }


        vm.posteColumns = [{
            name: 'libelle',
            title: 'Libelle'
        }];

        loadPostes();

        function loadPostes() {
            vm.postes = Poste.query();

        }

        $scope.$watch("vm.members.lieuNaissanceId", function () {
            if (vm.members.lieuNaissanceId != null) {

                Country.get({
                    id: vm.members.lieuNaissanceId
                }, function (data) {
                    vm.members.lieuNaissance = data.libele;
                })
            }

        });


        vm.villeColumns = [{
            name: 'libelle',
            title: 'Libelle'
        }];
        $scope.$on("onLoadVilles", function () {

        });

        $scope.$on("onLoadVillesBF", function () {

        });
        loadVillesBF();

        function loadVillesBF() {
            CityByCountryId.query({
                id: 1001
            }, function (data) {
                vm.countriesVille = data;
            })


            // vm.members.lieuNaissance = test.libele;
        }


        $scope.$on("onLoadVillesUSA", function () {

        });
        loadVillesUSA();

        function loadVillesUSA() {
            CityByCountryId.query({
                id: 1014
            }, function (data) {
                vm.countriesVilleUSA = data;
            })


            // vm.members.lieuNaissance = test.libele;
        }


        $scope.$watch("vm.members.villeArriveId", function () {
            if (vm.members.statesId != null) {
                CityByStateId.query({
                    id: vm.members.statesId
                }, function (data) {
                    vm.countriesVilleUSA = data;
                })
            }
            if (vm.members.villeArriveId != null) {
                City.get({
                    id: vm.members.villeArriveId
                }, function (data) {
                    vm.members.villeArrivee = data.libelle;
                    vm.members.etatArrivee = data.statesLibelle;
                })
            }

        });

        $scope.$watch("vm.members.villeId", function () {
            if (vm.members.villeId != null) {

                City.get({
                    id: vm.members.villeId
                }, function (data) {
                    vm.members.ville = data.libelle;
                })
            }

        });


        vm.etatColumns = [{
            name: 'libelle',
            title: 'Libelle'
        }];
        $scope.$on("onLoadEtats", function () {

        });
        loadEtats();

        function loadEtats() {
            States.query({
                size: 10000
            }, function (data) {
                if (data != null) {
                    vm.etats = [];
                    vm.etats = data;
                }
            });


            // vm.members.lieuNaissance = test.libele;
        }

        vm.metierColumns = [{
            name: 'libelle',
            title: 'Libelle'
        }];
        $scope.$on("onLoadMetier", function () {

        });
        loadMetier();

        function loadMetier() {
            vm.metiers = Metier.query({
                size: 1000
            });

        }

        $scope.$watch("vm.members.metierId", function () {
            if (vm.members.metierId != null) {
                Metier.get({
                    id: vm.members.metierId
                }, function (data) {
                    vm.members.metier = data.libelle;
                })
            }

        });


        vm.statesVilleColumns = [{
            name: 'libelle',
            title: 'Libelle'
        }];
        $scope.$on("onLoadStatesVille", function () {

        });


        $scope.$watch("vm.members.lieuNaissanceId", function () {
            if (vm.members.lieuNaissanceId != null) {
                Country.get({
                    id: vm.members.lieuNaissanceId
                }, function (data) {
                    vm.members.lieuNaissance = data.libele;
                })

            }

        });


        $scope.$watch("vm.members.country.statesId", function () {
            loadEtats();
            if (vm.members.country.statesId != null) {
                CityByStateId.query({
                    id: vm.members.country.statesId
                }, function (data) {
                    vm.statesVille = data;
                })


                vm.members.country.states = $filter('filter')(vm.etats, {id: vm.members.country.statesId}, true)[0].libelle;

            }

        });


        $scope.$watch("vm.members.country.cityId", function () {

            if (vm.members.country.statesId != null) {
                CityByStateId.query({
                    id: vm.members.country.statesId
                }, function (data) {
                    vm.statesVille = data;
                    vm.members.country.city = $filter('filter')(vm.statesVille, {id: vm.members.country.cityId}, true)[0].libelle;

                })
            }

        });


        $scope.$watch("vm.members.adresseDTO.etatId", function () {
            loadEtats();
            if (vm.members.adresseDTO.etatId != null) {
                CityByStateId.query({
                    id: vm.members.adresseDTO.etatId
                }, function (data) {
                    vm.villeAdresse = data;
                })
                vm.members.adresseDTO.etat = $filter('filter')(vm.etats, {id: vm.members.adresseDTO.etatId}, true)[0].libelle;
            }
        });


        $scope.$watch("vm.members.adresseDTO.cityId", function () {
            if (vm.members.adresseDTO.etatId != null) {
                CityByStateId.query({
                    id: vm.members.adresseDTO.etatId
                }, function (data) {
                    vm.villeAdresse = data;
                    vm.members.adresseDTO.city = $filter('filter')(vm.villeAdresse, {id: vm.members.adresseDTO.cityId}, true)[0].libelle;
                })
            }

        });


        $scope.$watch("vm.members.statesId", function () {
            loadEtats();
            if (vm.members.statesId != null) {
                CityByStateId.query({
                    id: vm.members.statesId
                }, function (data) {
                    vm.countriesVilleUSA = data;
                })

                vm.members.states = $filter('filter')(vm.etats, {id: vm.members.statesId}, true)[0].libelle;

            }

        });

        $scope.$watch("vm.members.bfIdDoc.cityId", function () {
            loadVillesBF();
            vm.members.bfIdDoc.city = $filter('filter')(vm.countriesVille, {id: vm.members.bfIdDoc.cityId}, true)[0].libelle;

        });


        vm.colums = [{
            name: 'nom',
            title: 'Nom'
        }];


        $scope.$watch("vm.associationId", function (ids) {

            Association.query(function (data) {
                vm.assocs = data;
            })


            if (angular.isDefined(ids)) {
                vm.members.associations = [];
                angular.forEach(ids, function (value, index) {
                    var auth = _.find(vm.assocs, {'id': value});
                    vm.members.associations.push(auth);
                });
            }
        });


        vm.setPhoto = function ($file, members) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        members.photo = base64Data;
                        members.photoContentType = $file.type;
                    });
                });
            }
        };

        vm.setSignature = function ($file, consulat) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        consulat.signature = base64Data;
                        consulat.signatureContentType = $file.type;
                    });
                });
            }
        };


        //  function addStates()
        //  {

        var addItemData = {
            templateUrl: 'app/entities/states/states-dialog.html',
            controller: 'StatesDialogController',
            service: 'States',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                isViewing: function () {
                    return false;
                },
                isHiding: function () {
                    return true;
                },
                entity: function () {
                    return {
                        /*Initialisation des valeurs par défauts de l'entité à créer*/
                        id: null,
                        libelle: null,
                        countryLibelle: null

                    };
                }
            }
        };

        var addItemDataSecond = {
            templateUrl: 'app/entities/city/city-dialog.html',
            controller: 'CityDialogController',
            service: 'City',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                isViewing: function () {
                    return false;
                },
                isHiding: function () {
                    return true;
                },
                entity: function () {
                    return {
                        /*Initialisation des valeurs par défauts de l'entité à créer*/
                        id: null,
                        libelle: null,
                        countriesLibelle: null,
                        statesLibelle: null

                    };
                }
            }
        };

        var dialogData = {
            addData: addItemData,
            addDataSecond: addItemDataSecond
        };


        var datasEmpty = [];


        var statesWindow = {
            vm: vm,
            datas: datasEmpty,
            dialogData: dialogData,
            scope: $scope
        };
        PopupService.open(statesWindow);

        $rootScope.$on('PopupService:resultData', function (event, result) {
            if (angular.isDefined(result.result.statesId) && result.result.statesId == vm.members.adresseDTO.etatId) {
                vm.villeAdresse.push(result.result);
            } else {
                vm.etats.push(result.result);
            }


        });


    }
})();
