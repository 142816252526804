(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('Members', Members)
        .factory('MemberMultiParams', MemberMultiParams)
        .factory('MemberExisting', MemberExisting)
        .factory('CarteMultiParams', CarteMultiParams);


    Members.$inject = ['$resource', 'DateUtils'];
    MemberMultiParams.$inject = ['$resource', 'DateUtils'];
    MemberExisting.$inject = ['$resource', 'DateUtils'];
    CarteMultiParams.$inject = ['$resource', 'DateUtils'];

    function CarteMultiParams ($resource, DateUtils) {
        var resourceUrl =  'api/cartes/findByParams';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateNaissance = DateUtils.convertLocalDateFromServer(data.dateNaissance);
                    }
                    return data;
                }
            }
        });
    }



    function MemberMultiParams ($resource, DateUtils) {
        var resourceUrl =  'api/members/findByParams';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateNaissance = DateUtils.convertLocalDateFromServer(data.dateNaissance);
                        data.dateArrivee = DateUtils.convertLocalDateFromServer(data.dateArrivee);
                    }
                    return data;
                }
            }
        });
    }

    function MemberExisting ($resource, DateUtils) {
        console.log("---------------ffffffffffffffffffffffffffff-------------------------->");
        var resourceUrl =  'api/members/test';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateNaissance = DateUtils.convertLocalDateToServer(copy.dateNaissance);
                    copy.dateArrivee = DateUtils.convertLocalDateToServer(copy.dateArrivee);

                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateNaissance = DateUtils.convertLocalDateToServer(copy.dateNaissance);
                    copy.dateArrivee = DateUtils.convertLocalDateToServer(copy.dateArrivee);

                }
            },
            'sauvegarder': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateNaissance = DateUtils.convertLocalDateToServer(copy.dateNaissance);
                    copy.dateArrivee = DateUtils.convertLocalDateToServer(copy.dateArrivee);

                }
            }
        });
    }






    function Members ($resource, DateUtils) {
        var resourceUrl =  'api/members/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateNaissance = DateUtils.convertLocalDateFromServer(data.dateNaissance);
                        data.dateArrivee = DateUtils.convertLocalDateFromServer(data.dateArrivee);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateNaissance = DateUtils.convertLocalDateToServer(copy.dateNaissance);
                    copy.dateArrivee = DateUtils.convertLocalDateToServer(copy.dateArrivee);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateNaissance = DateUtils.convertLocalDateToServer(copy.dateNaissance);
                    copy.dateArrivee = DateUtils.convertLocalDateToServer(copy.dateArrivee);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
