(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User','$scope', 'TableService'];

    function UserManagementController(Principal, User, $scope, TableService) {
        var vm = this;

        vm.currentAccount = null;
        vm.setActive = setActive;


        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        var addItemData ={
            templateUrl: 'app/admin/user-management/user-management-dialog.html',
            controller: 'UserManagementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                isViewing:function(){
                    return false;
                },
                isEdit:function(){
                    return false;
                },
                entity: function () {
                    return {
                        login: null,
                        email: null,
                        id: null
                    };
                }
            }
        };

        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/admin/user-management/user-management-dialog.html',
            controller: 'UserManagementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                isViewing:function(){
                    return false;
                },
                isEdit:function(){
                    return true;
                },
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData ={
            templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
            controller: 'UserManagementDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var detailItemData = {
            templateUrl: 'app/admin/user-management/user-management-dialog.html',
            controller: 'UserManagementDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                isViewing:function(){
                    return true;
                },
                isEdit:function(){
                    return false;
                },
                entity: null //valeur déterminé à l'appel de la boite
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData,
            detailData: detailItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {

            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: User,
            entitySearch: User,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };

        TableService.init(datas);

        vm.setItemsPerPage(10);
    
    }
})();
