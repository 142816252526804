(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('DocumentLegalDeleteController',DocumentLegalDeleteController);

    DocumentLegalDeleteController.$inject = ['$uibModalInstance', 'entity', 'DocumentLegal'];

    function DocumentLegalDeleteController($uibModalInstance, entity, DocumentLegal) {
        var vm = this;

        vm.documentLegal = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DocumentLegal.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
