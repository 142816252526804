(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('InfoPosteDetailController', InfoPosteDetailController);

    InfoPosteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InfoPoste', 'Members', 'Poste'];

    function InfoPosteDetailController($scope, $rootScope, $stateParams, previousState, entity, InfoPoste, Members, Poste) {
        var vm = this;

        vm.infoPoste = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:infoPosteUpdate', function(event, result) {
            vm.infoPoste = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
