(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('CityDialogController', CityDialogController);

    CityDialogController.$inject = ['$timeout', '$scope', 'StatesByCountry', '$uibModalInstance', 'entity', 'City', 'Country', 'States'];

    function CityDialogController ($timeout, $scope, StatesByCountry, $uibModalInstance, entity, City, Country, States) {
        var vm = this;

        vm.city = entity;
        vm.clear = clear;
        vm.save = save;

        vm.countryColumns = [{
            name: 'libele',
            title: 'Libelle'
        }];
        $scope.$on("onLoadCountries", function () {

        });
        loadCountries();

        function loadCountries() {
            Country.query({
                size: 10000
            }, function (data) {
                console.log("les pays");
                if (data != null) {
                    console.log(data);
                    vm.countries = [];
                    vm.countries = data;
                }
            });

           // vm.members.lieuNaissance = test.libele;
        }
        vm.statesColumns = [{
            name: 'libelle',
            title: 'Libelle'
        }];

        $scope.$watch("vm.city.countriesId", function () {
            console.log("Dans le watch");
  if(vm.city.countriesId!=null)
{
                StatesByCountry.query ({
                    id: vm.city.countriesId
                }, function (data) {
                    vm.states = data;
                })
            }

        });
  


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.city.id !== null) {
                City.update(vm.city, onSaveSuccess, onSaveError);
            } else {
                City.save(vm.city, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:cityUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
