(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembersDialogController', MembersDialogController);

    MembersDialogController.$inject = ['$http','DataUtils','AdressesByMember', 'InfoAssociationByMember', '$window', '$uibModalInstance', 'entity', 'Poste', 'UserExtra', 'CountryIdDoc', 'InfoPosteByMember', 'DocumentLegal'];

    function MembersDialogController ($http,DataUtils,AdressesByMember, InfoAssociationByMember, $window, $uibModalInstance, entity, Poste, UserExtra, CountryIdDoc, InfoPosteByMember, DocumentLegal) {
        var vm = this;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.documents = DocumentLegal.query();


        vm.postes = Poste.query();
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.members = entity;

        var fileURLPDF;

        vm.etatCarte=etatCarte;

        vm.members.dateDelivr = new Date(vm.members.dateDelivr);

        vm.init = init;

        InfoPosteByMember.query({id:vm.members.id},
            function (data) {
                vm.infoPostes=data;
            }
            );



        function init()
{InfoAssociationByMember.query({id:vm.members.id},
            function (data) {
                vm.members.associations=data;
            });


            AdressesByMember.query({id:vm.members.id},
                function (data) {
                    vm.members.adresseDTO=data;
                }
                );
        }

        vm.init();
        vm.clear = clear;
             /*****************************Gestionnaire des onglets**************************************/
        vm.steps = [
            'IDENTIFICATION',
            'CONTACTS AND ARRIVALS',
            'DOC IDENTIFICATION',
            'ASSOCIATION',
            'OCCUPATIONS HISTORY',

        ];


        vm.selection = vm.steps[0];
        vm.error = false;
        vm.showOnglet = showOnglet;
        vm.getCurrentStepIndex = function() {
            return vm.steps.indexOf(vm.selection);
        };

        function showOnglet(onglet) {
            return (vm.selection.toUpperCase() === onglet.toUpperCase());
        }

            // Go to a defined step index
        vm.goToStep = function(index, editForm) {
            vm.selection = vm.steps[index];
        };

        vm.hasNextStep = function() {
            var stepIndex = vm.getCurrentStepIndex();
            var nextStep = stepIndex + 1;
                // Return true if there is a next step, false if not
            return !angular.isUndefined(vm.steps[nextStep]);
        };

        vm.hasPreviousStep = function() {
            var stepIndex = vm.getCurrentStepIndex();
            var previousStep = stepIndex - 1;
                // Return true if there is a next step, false if not
            return !angular.isUndefined(vm.steps[previousStep]);
        };

        vm.incrementStep = function() {
            if (vm.hasNextStep()) {
                    //Vérification des champs du step précedent
                var stepIndex = vm.getCurrentStepIndex();
                var nextStep = stepIndex + 1;
                vm.selection = vm.steps[nextStep];
            }
        };


        vm.decrementStep = function() {
            if (vm.hasPreviousStep()) {
                var stepIndex = vm.getCurrentStepIndex();
                var previousStep = stepIndex - 1;
                vm.selection = vm.steps[previousStep];
            }
        };



        function etatCarte() {
            $http.get('/api/members/cartePDF', {
                responseType: 'arraybuffer',
                params: {
                    idMembre: vm.members.id,
                    changement:true

                }

            })
                    .success(function(response) {
                        var file = new Blob([(response)], {
                            type: 'application/pdf'
                        });
                        fileURLPDF = URL.createObjectURL(file);
                        $window.open(fileURLPDF, '_blank');



                    });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


    }
})();
