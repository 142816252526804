(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('UserExtraForgotPassController', UserExtraForgotPassController);

        UserExtraForgotPassController.$inject = ['ProfilByUser', '$scope', 'Notification', 'User', 'Consulat', 'Profil', 'Members'];

    function UserExtraForgotPassController (ProfilByUser, $scope, Notification, User, Consulat, Profil, Members) {
       
        var vm = this;

        vm.save = save;
    
     
       
        function onSaveSuccess(result) {
            vm.isSaving = false;
        }



        //Pour une modification effectué par un assistant
        

        function onSaveError() {
            vm.isSaving = false;
   
        }

        function save() {
            vm.isSaving = true;
  
            console.log(vm.user);
                if(vm.confirmEmail !== vm.email) {
                    Notification.error("L'email' et sa confirmation ne sont pas égaux !");
                    onSaveError();
                }else{
                console.log("mise à jour");
                User.update(vm.user, onSaveSuccess, onSaveError);
                }
           
        }

    }
})();
