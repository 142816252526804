(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('BfIdDocDetailController', BfIdDocDetailController);

    BfIdDocDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BfIdDoc'];

    function BfIdDocDetailController($scope, $rootScope, $stateParams, previousState, entity, BfIdDoc) {
        var vm = this;

        vm.bfIdDoc = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:bfIdDocUpdate', function(event, result) {
            vm.bfIdDoc = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
